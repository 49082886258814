import { AxiosRequestConfig, CancelToken } from 'axios'
import UniversalFormData from 'form-data'
import https from 'https'
import { v4 as uuidv4 } from 'uuid'

import appConfig from '#config'
import { fileTransferApi } from '#modules/api'
import * as AdminApiTypes from '#modules/api/admin/types'
import { HttpClient } from '#modules/api/httpClient'
import * as MainApiTypes from '#modules/api/main/types'
import * as ClientApiTypes from '#modules/api/personal/types'
import { ICodeError, ICodeWithMessage, ResponseCode } from '#modules/api/types/common'
import { getFileInfo, getScreenParams } from '#services/helper'
import init, { Actions } from '#services/userActionLogger'

let config: AxiosRequestConfig
if (process.env.__CLIENT__ && process.env.NODE_ENV === 'production') {
  config = {
    baseURL: new URL('/api', appConfig.apiHost).href
  }
} else {
  config = {
    baseURL: new URL('/api', appConfig.apiHost).href,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    })
  }
}

export interface IGetAnnouncesSuccess extends ICodeWithMessage {
  data: AdminApiTypes.TArticle[]
}

type TGetAnnouncesResponse = IGetAnnouncesSuccess | ICodeError

export class MainSiteApi extends HttpClient {
  public constructor(axiosConfig: AxiosRequestConfig) {
    super(axiosConfig)
    this._initializeRequestInterceptor()
    this._initializeResponseInterceptor()
  }

  public getRss(type?: string): Promise<unknown> {
    let params = {}
    if (type) params = { ...params, type }
    return this.instance.get('/rss', { params })
  }

  public saveIncomingStat(data: FormData): Promise<void> {
    return this.instance.post('/register/saveIncomingStat', data)
  }

  public getSitemapCard(): Promise<AdminApiTypes.TGetPagesWithPageGroup> {
    return this.instance.get('/sitemap/getSitemapCard')
  }

  public getAnnounces(
    params: string | { [key in string]: string | number },
    extraParams?: { [key in string]: string | number }
  ): Promise<TGetAnnouncesResponse> {
    if (typeof params === 'object')
      return this.instance.get('/getAnnounces', { params: { ...params } })
    if (typeof extraParams === 'object')
      return this.instance.get(`/getAnnounces${params}`, { params: { ...extraParams } })

    return this.instance.get(`/getAnnounces${params}`)
  }

  public getFullArticleList(): Promise<AdminApiTypes.TGetFullArticleList> {
    return this.instance.get('/sitemap/getFullArticleList')
  }

  public downloadDocument(params: { document: string; fio: string }): Promise<Blob> {
    return this.instance.get('/downloadDocument', { params, responseType: 'blob' })
  }

  public confirmPhoneCode(
    formData: FormData,
    params: {
      token: string
      code: string
      registrationType: string
    }
  ): Promise<unknown> {
    return this.instance.post('/register/confirmPhoneCode', formData, { params })
  }

  /**
   * Получает дефолтный промокод для не зарегистрированной персоны
   */
  public getDefaultPromo(params: {
    groupId?: number
  }): Promise<ClientApiTypes.TGetDefaultPromoResponse> {
    const { groupId = null } = params
    return this.instance.get('/getDefaultPromo', { params: { groupId } })
  }

  /**
   * Получает loanConditions для не зарегистрированной персоны
   */
  public getSliderInfo = (
    params: {
      promoCode?: string
      groupId?: number
    } = {}
  ): Promise<ClientApiTypes.TGetSliderInfoResponse> => {
    const { promoCode = '', groupId = null } = params
    return this.instance.get('/getSliderInfo', { params: { promoCode, groupId } })
  }

  public getRegistrationForm = ({
    brokerToken
  }: {
    brokerToken: string
  }): Promise<MainApiTypes.TGetRegistrationForm> =>
    this.instance.get('/getRegistrationForm', { params: { brokerToken } })

  public getFmsByCode = async (params: {
    code: number
    token: string
  }): Promise<MainApiTypes.TGetFmsByCodeResponse> => this.instance.get('/fmsCodes', { params })

  public getBicCodes = async (
    bik: string,
    token: string
  ): Promise<MainApiTypes.TGetBicCodesModifiedResponse> => {
    const response: MainApiTypes.TGetBicCodesResponse = await this.instance.get('/bikCodes', {
      params: { bik, token }
    })
    const { code } = response
    if (code === ResponseCode.success) {
      const { data, ...rest } = response
      const resolveData = data.map((item) => ({
        value: item.bik,
        text: `${item.bik} - ${item.bankName}`,
        note: item.bankName
      }))
      return { ...rest, data: resolveData }
    }
    return response
  }

  public detachPromoCode = async (
    data: FormData,
    params: { token: string }
  ): Promise<ClientApiTypes.TCustomResponse> =>
    this.instance.post('/detachPromoCode', data, { params })

  public repeatCode = async (params: { token: string }): Promise<ClientApiTypes.TCustomResponse> =>
    this.instance.get('/register/repeatCode', { params })

  public repeatLink = async (params: { token: string }): Promise<ClientApiTypes.TCustomResponse> =>
    this.instance.get('/register/repeatLink', { params })

  public registerPerson = async (
    data: FormData,
    params: { token: string }
  ): Promise<MainApiTypes.TRegisterPersonResponse> =>
    this.instance.post('/register/person', data, { params })

  public registrationRestore = async (
    token: string
  ): Promise<MainApiTypes.TRegistrationRestoreResponse> =>
    this.instance.get('/register/restore', { params: { token } })

  public cardUpload = async (
    token: string,
    cardImage: File,
    config = {}
  ): Promise<ClientApiTypes.TCustomResponse> => {
    const requestConfig = {
      retry: 3,
      retryTime: 2000,
      channel: 'regularCropper',
      ...config
    }

    const actionLogger = init({
      action: Actions.CARD_UPLOAD,
      token,
      staticGuid: true
    })
    await actionLogger.logRequest({
      channel: requestConfig.channel,
      fileInfo: await getFileInfo(cardImage),
      screen: getScreenParams()
    })

    const fileName = cardImage.name || [uuidv4(), 'jpeg'].join('.')
    const formData = new UniversalFormData()
    formData.append('token', token)
    formData.append('cardImage', cardImage, fileName)
    formData.append('requestGuid', actionLogger.staticGuid || uuidv4())

    return this.instance.post('/card/upload', formData, { params: requestConfig })
  }

  public cardCrop = (
    token: string,
    formData: FormData,
    config = {}
  ): Promise<ClientApiTypes.TCustomResponse> => {
    formData.append('token', token)
    return this.instance.post('/card/crop', formData, config)
  }

  public canNotUploadCard = (params: {
    token: string
  }): Promise<MainApiTypes.TCanNotUploadCardResponse> =>
    this.instance.post('/card/canNotUploadCard', { params })

  public cardOriginal = (token: string): Promise<MainApiTypes.TCardOriginalResponse> =>
    this.instance.get('/card/get', { params: { token }, responseType: 'blob' })

  public cardClear = (token: string): Promise<ClientApiTypes.TCustomResponse> =>
    this.instance.post(`/card/clear?token=${token}`)

  public clientSubmitRegistration = (
    data: FormData,
    params: { token: string }
  ): Promise<MainApiTypes.TClientSubmitRegistrationResponse> =>
    this.instance.post('/register/submit', data, { params })

  public saveLoanWay = (data: FormData, params: { token: string }): Promise<void> =>
    this.instance.post('/register/saveLoanWay', data, { params })

  public getInn = (params: { token: string }): Promise<MainApiTypes.TGetInnResponse> =>
    this.instance.get('/getInn', { params })

  public getContent = (params: {
    document: string
    fio: string
  }): Promise<MainApiTypes.TGetContentResponse> => this.instance.get('/getContent', { params })

  public getIndex = (address: string): Promise<MainApiTypes.TGetIndexResponse> =>
    this.instance.get('/postalCode', { params: { address, top: 1 } })

  public getTestimonials = (params: {
    published?: number
    random?: number
    limit?: number
    id?: number
    fp?: number
    offset?: number
    sort?: number
  }): Promise<MainApiTypes.TGetTestimonialsResponse> =>
    this.instance.get('/getTestimonials', { params })

  public addTestimonial = (data: {
    email: string
    name: string
    info: string
    city?: string
    userPhoto?: Blob
  }): Promise<MainApiTypes.TAddTestimonialResponse> => this.instance.post('/addTestimonial', data)

  public canNotFillForm = (params: { token: string }): Promise<ClientApiTypes.TCustomResponse> =>
    this.instance.post('/register/canNotFillForm', { params })

  public attachPromoCode = (
    data: FormData,
    params: { token: string }
  ): Promise<ClientApiTypes.TCustomResponse> =>
    this.instance.post('/attachPromoCode', data, { params })

  public getSearchableFields = (): Promise<MainApiTypes.TGetSearchableFieldsResponse> =>
    this.instance.get('/getSearchableFields')

  public personConfirmEmail = (params: {
    pid: number
    code: string
    bonus: number
  }): Promise<ClientApiTypes.TCustomResponse> =>
    this.instance.get('/register/verifyEmail', { params })

  public personSubscribe = (params: {
    action: 'subscribe' | 'unsubscribe'
    pid: number
    type: string
    code: string
  }): Promise<ClientApiTypes.TCustomResponse> => this.instance.get('/promo/subscribe', { params })

  public getSettings = (
    settings: { token: string; settings?: Array<any> }[] | [] = []
  ): Promise<MainApiTypes.TGetSettingsResponse> => {
    const formData = new UniversalFormData()
    settings.forEach((settingName) => {
      formData.append('settings[]', settingName)
    })
    return this.instance.post('/getSettings', formData)
  }

  public getCardPhotoForBrokerUploadSettings = (
    phoneToken: string,
    id: number
  ): Promise<MainApiTypes.TGtCardPhotoForBrokerUploadSettingsResponse> =>
    this.instance.get('/getCardPhotoForBrokerUploadSettings', { params: { token: phoneToken, id } })

  public personUploadFile = (params: {
    file: File
    token: string
  }): Promise<ClientApiTypes.TCustomResponse> => {
    const { file, token } = params
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('token', token)
    return fileTransferApi.post(
      new URL('/api/uploadCardPhotoForBroker', appConfig.apiHost).href,
      formData
    )
  }

  public async sendCardPhoto({
    token,
    file,
    matrix,
    sse = null,
    cancelToken = null,
    cardPhotoId = ''
  }: {
    token: string
    file: File
    matrix: Array<number[]>
    sse: TNullable<string>
    cancelToken: TNullable<CancelToken>
    cardPhotoId: string
  }): Promise<ClientApiTypes.TCropCardPhotoResponse> {
    const cropData = new FormData()
    sse && cropData.append('sse', sse)
    cardPhotoId && cropData.append('cardPhotoId', cardPhotoId)
    matrix
      .slice(0, 2)
      .forEach((row, rInd) =>
        row.forEach((cell, cInd) => cropData.append(`matrix[${rInd}][${cInd}]`, cell.toFixed(7)))
      )
    const config = { channel: 'cameraCropper' } as { channel: string; cancelToken?: CancelToken }
    if (cancelToken) config.cancelToken = cancelToken
    const response = await this.cardUpload(token, file, config)
    if (response.code !== 0) throw response
    return this.cardCrop(token, cropData, config)
  }

  public getPassportCityByFmsNameAndCode = (
    token: string,
    fmsName = '',
    fmsCode = ''
  ): Promise<MainApiTypes.TGetPassportCityByFmsNameAndCodeResponse> => {
    const normalizedCode = String(fmsCode).replace(/\D/g, '')
    return this.instance.get('/fmsCity', { params: { token, name: fmsName, code: normalizedCode } })
  }

  public getPartnerGidFromQueryString = (params: {
    aprt159: string
    exp: TNullable<string>
    dat: TNullable<string>
    sign: TNullable<string>
    userPhone: TNullable<string>
    frontType: TNullable<string>
  }): Promise<MainApiTypes.TGetPartnerGidResponse> =>
    this.instance.get('/getPartnerGid', { params })
}

export const mainSiteApi = new MainSiteApi(config)

export const axiosRequestConfig = config
