import { TFaqItem } from '#components/FAQ/types'
import { ROUTES } from '#src/constants/common'

import { items as getLoanQuestions } from '../FAQ/FAQPage/GetLoan/item'
import { items as holidaysQuestions } from '../FAQ/FAQPage/Holidays/item'
import { items as lkQuestions } from '../FAQ/FAQPage/Lk/item'
import { items as processingQuestions } from '../FAQ/FAQPage/Processing/item'
import { items as questionnaireQuestions } from '../FAQ/FAQPage/Questionnaire/item'
import { items as returnLoanQuestions } from '../FAQ/FAQPage/ReturnLoan/item'

const exportQuestionFaqToJson = (faqItem: TFaqItem[]): string => {
  const schemaQuestions = faqItem.map(({ title, text }: { title: string; text: string }) => ({
    '@type': 'Question',
    name: title,
    acceptedAnswer: {
      '@type': 'Answer',
      text
    }
  }))

  return JSON.stringify(schemaQuestions)
}

const getLoanQuestionsJson = exportQuestionFaqToJson(getLoanQuestions)
const processingQuestionsJson = exportQuestionFaqToJson(processingQuestions)
const returnLoanQuestionsJson = exportQuestionFaqToJson(returnLoanQuestions)
const questionnaireQuestionsJson = exportQuestionFaqToJson(questionnaireQuestions)
const holidaysQuestionsJson = exportQuestionFaqToJson(holidaysQuestions)
const lkQuestionsJson = exportQuestionFaqToJson(lkQuestions)
const mainPage = `
[
  { 
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "ЧЕСТНОЕ СЛОВО",
    "legalName" : "ООО МКК «ЧЕСТНОЕ СЛОВО»",
    "url": "https://4slovo.ru/",
    "logo": "https://4slovo.ru/img/logo.jpg",
    "foundingDate": "2013",
    "email": "info@4slovo.ru",
    "image": "https://4slovo.ru/img/logo.jpg",
    "telephone": "+7(499)670-07-60",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "1-й Колобовский пер, д. 6, стр.3,пом. Б, комн. 7-21",
      "addressLocality": "г. Москва",
      "addressRegion": "г. Москва",
      "postalCode": "127051",
      "addressCountry": "Россия"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "customer support",
      "telephone": "[+7(499)670-07-60]",
      "email": "info@4slovo.ru"
    }
  },
  { 
    "@context": "https://schema.org",
    "@type": "FinancialProduct",
    "name": "Займ»",
    "description": "Онлайн-сервис, который открывает доступ к заёмным средствам гражданам РФ",
    "offers": {
      "@type": "Offer",
      "offeredBy": "https://4slovo.ru/register/"
    },
    "brand": "ООО МКК «ЧЕСТНОЕ СЛОВО»",
    "logo": "https://4slovo.ru/img/logo.jpg"
  }
]
`
const getLoanSchema = `
  { 
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "headline": "Получение займа",
    "datePublished": "2022-10-26",
    "dateCreated": "2022-10-26",
    "mainEntity": ${getLoanQuestionsJson}
  }
`
const processingSchema = `
  { 
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "headline": "Обработка заявки",
    "datePublished": "2022-10-26",
    "dateCreated": "2022-10-26",
    "mainEntity": ${processingQuestionsJson}
  }
`
const returnLoanSchema = `
  { 
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "headline": "Возврат займа",
    "datePublished": "2022-10-26",
    "dateCreated": "2022-10-26",
    "mainEntity": ${returnLoanQuestionsJson}
  }
`

const questionnaireSchema = `
  { 
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "headline": "Заполнение заявки",
    "datePublished": "2022-10-26",
    "dateCreated": "2022-10-26",
    "mainEntity": ${questionnaireQuestionsJson}
  }
`

const holidaysQuestionsSchema = `
  { 
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "headline": "Кредитные каникулы",
    "datePublished": "2022-10-26",
    "dateCreated": "2022-10-26",
    "mainEntity": ${holidaysQuestionsJson}
  }
`

const lkSchema = `
  { 
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "headline": "Личный кабинет",
    "datePublished": "2022-10-26",
    "dateCreated": "2022-10-26",
    "mainEntity": ${lkQuestionsJson}
  }
`

const model = {
  [ROUTES.main]: mainPage,
  [ROUTES.faq]: getLoanSchema,
  [ROUTES.faqReq]: processingSchema,
  [ROUTES.faqGetLoan]: returnLoanSchema,
  [ROUTES.faqForm]: questionnaireSchema,
  [ROUTES.faqHolidays]: holidaysQuestionsSchema,
  [ROUTES.faqLk]: lkSchema
}

export const getSchema = (pathname: string): string => model[pathname]
