import { TFaqItem } from '#components/FAQ/types'

export const items: TFaqItem[] = [
  {
    title: 'Как восстановить доступ в личный кабинет? Забыл пароль.',
    text:
      '<a href="https://person.4slovo.ru/change-password" target="_blank">Запросите новый пароль</a> на странице входа в личный кабинет. Если вы не помните логин-телефон или пароль не приходит, напишите в <a href="https://4slovo.ru/contact" target="_blank">службу поддержки</a> — на связи с 10:00 до 22:00 по Москве. В обращении приложите селфи с паспортом. <br /><br />Также вы можете посмотреть видео <a href="https://www.youtube.com/embed/Zi93iMBvuNA" target="_blank">«Как восстановить пароль».</a>'
  },
  {
    title: 'Не помню номер телефона для входа. Что делать?',
    text:
      'Вход в личный кабинет осуществляется по номеру мобильного телефона, который был указан при регистрации. Чтобы узнать на какой телефон вы регистрировали профиль, <a href="https://4slovo.ru/contact" target="_blank">отправьте запрос</a> в службу поддержки. Она работает с 10:00 до 22:00 по Москве. В запросе укажите полные ФИО и дату рождения.'
  },
  {
    title: 'Забыл email. Что делать?',
    text:
      'Вместо адреса электронной почты введите ваш номер мобильного телефона, который был указан при регистрации. Чтобы узнать, на какой телефон вы регистрировали профиль, <a href="https://4slovo.ru/contact" target="_blank">отправьте запрос</a> в службу поддержки. Она работает с 10:00 до 22:00 по Москве. В запросе укажите полные ФИО и дату рождения.'
  },
  {
    title: 'Нет доступа к номеру телефона. Что делать?',
    text:
      'Для восстановления номера обратитесь к вашему оператору, если возможности восстановить номер нет, то нужно зарегистрировать новый личный кабинет. Чтобы сохранить статус в программе привилегий, <a href="https://4slovo.ru/contact" target="_blank">отправьте запрос</a> службе поддержке, им понадобятся ваши старый и новый номера, плюс фото главного разворота паспорта (страницы 2 и 3).'
  },
  {
    title: 'Как изменить данные в личном кабинете?',
    text:
      'Изменить способ получения займа можно на вкладке «Реквизиты»: кликните на поле «Добавить реквизиты».<br /><br /> Если вы хотите поменять email, на который получаете от нас письма, это можно сделать в разделе «Мои данные».<br /><br />Чтобы изменить другую информацию, оставьте заявку в <a href="https://4slovo.ru/contact" target="_blank">форме обратной связи</a>. Укажите логин — номер мобильного телефона, ФИО и данные, которые нужно заменить.'
  },
  {
    title: 'Как сменить данные паспорта?',
    text:
      'Показываем все этапы в коротком <a href="https://www.youtube.com/shorts/xd3-TMMxYgo" target="_blank">видео</a>. <ol><li>Скачайте заявление об изменении персональных данных по <a href="https://4slovo.ru/images/upload/files/%D0%97%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BE%20%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%9F%D0%94.docx" target="_blank">ссылке</a>, в личном кабинете или запросите у службы поддержки.</li><li> Заполните форму, <i>включая пустые строки</i>!</li><li>Сделайте скан или фото паспорта — нам понадобится следующая информация:<ul><li>ФИО, дата выдачи и подпись ( страницы 2 и 3);</li><li>данные прежних паспортов (страница 19);</li><li>данные о прописке, если меняли (страницы от 5 до 12);</li></ul><li>Укажите логин, прикрепите подписанное заявление и страницы паспорта и отправьте по <a href="https://4slovo.ru/contact" target="_blank">форме обратной связи</a> или на info@4slovo.ru.</li>'
  },
  {
    title: 'Почему в личном кабинете есть уведомление о договоре залога?',
    text:
      'Этот договор не касается имущества заёмщика: мы ничего не берём у вас в залог.<br />Документ относится к порядку расчётов между МКК «Честное слово» и банком ТКБ ПАО, но по закону мы обязаны уведомить о нём клиентов.'
  },
  {
    title: 'Как удалить аккаунт?',
    text:
      'В меню личного кабинета есть кнопка «Удаление кабинета». Удалить аккаунт можно, только если сейчас у вас нет открытых кредитов и страховок.'
  },
  {
    title: 'Как восстановить личный кабинет?',
    text:
      '<a href="https://4slovo.ru/contact" target="_blank">Напишите</a> службе поддержке по форме обратной связи, выбрав категорию «Восстановление личного кабинета». Прикрепите к запросу копию паспорта и селфи с ним. Рассказываем, <a href="https://4slovo.ru/kak-sdelat-fotografiyu-s-pasportom" target="_blank">как правильно делать селфи</a>.'
  }
]
